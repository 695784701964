var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticStyle:{"margin-bottom":"100px"}},[_c('v-row',{staticClass:"sc-title mt-7",attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"md":"8","sm":"6"}},[_c('div',[_c('h1',[_vm._v("EVALUATION DETAILS")])])]),_c('v-col',{attrs:{"md":"4","sm":"6"}},[_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'EvaluationList' })}}},[_vm._v("Back")])],1)])],1),_c('customer-info'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'EvaluationReschedule',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" Reschedule ")])],1),_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'EvalPrep', params: { id: _vm.$route.params.id } })}}},[_vm._v(" EvalPrep ")])],1),_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'PreEvalCall',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" PreEval Call ")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'CustomerInsight',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" Cust Insights ")])],1),_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'EvalDrive',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" Drive ")])],1),_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'EvalSurvey',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" Survey ")])],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({ name: 'EnterBid', params: { id: _vm.$route.params.id } })}}},[_vm._v(" Bid ")])],1),_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'EvalCloseout',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" CloseOut ")])],1),_c('v-col',{staticClass:"col-height",attrs:{"cols":"12","md":"4","sm":"4"}},[_c('v-btn',{staticClass:"custom-btn-block",attrs:{"color":"primary","elevation":"2","large":""},on:{"click":function($event){return _vm.$router.push({
            name: 'ScheduleFU',
            params: { id: _vm.$route.params.id },
          })}}},[_vm._v(" Sched FU ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }