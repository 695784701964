<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col md="8" sm="6">
        <div>
          <h1>EVALUATION DETAILS</h1>
        </div>
      </v-col>
      <v-col md="4" sm="6">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="$router.push({ name: 'EvaluationList' })"
            >Back</v-btn
          >
        </div>
      </v-col>
    </v-row>
    <customer-info />

    <v-row justify="center">
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({
              name: 'EvaluationReschedule',
              params: { id: $route.params.id },
            })
          "
        >
          Reschedule
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({ name: 'EvalPrep', params: { id: $route.params.id } })
          "
        >
          EvalPrep
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({
              name: 'PreEvalCall',
              params: { id: $route.params.id },
            })
          "
        >
          PreEval Call
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="
            $router.push({
              name: 'CustomerInsight',
              params: { id: $route.params.id },
            })
          "
          large
          class="custom-btn-block"
        >
          Cust Insights
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({
              name: 'EvalDrive',
              params: { id: $route.params.id },
            })
          "
        >
          Drive
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          @click="
            $router.push({
              name: 'EvalSurvey',
              params: { id: $route.params.id },
            })
          "
          large
          class="custom-btn-block"
        >
          Survey
        </v-btn>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({ name: 'EnterBid', params: { id: $route.params.id } })
          "
        >
          Bid
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({
              name: 'EvalCloseout',
              params: { id: $route.params.id },
            })
          "
        >
          CloseOut
        </v-btn>
      </v-col>
      <v-col cols="12" md="4" sm="4" class="col-height">
        <v-btn
          color="primary"
          elevation="2"
          large
          class="custom-btn-block"
          @click="
            $router.push({
              name: 'ScheduleFU',
              params: { id: $route.params.id },
            })
          "
        >
          Sched FU
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";
export default {
  components: { CustomerInfo },
  data() {
    return {};
  },
};
</script>

<style>
.col-height {
  height: 115px;
}

.custom-btn-block {
  height: 100% !important;
  width: 100% !important;
}
</style>
